const imgData_2021 = {
  the_five_ladies: {
    title: 'The Five Ladies',
    imgs: {
      full: {
        src: require('./the_five_ladies_2021_web.jpg'),
        width: 1500,
        height: 983,
      },
      thumb: {
        src: require('./the_five_ladies_2021_thumb.jpg'),
        width: 750,
        height: 491,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on Arches HP 300gsm watercolour paper. 580 x 380 mm, 2021.',
    price: 260,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'These ladies are Dorothy, Mary-Lou, Pinky, Borris and Potty, 5 pet sheep I had the pleasure to paint!<br /><br />The painting was commissioned as a birthday gift. The five ladies are living at a farm in Ireland, and they each have their own personalities. This determined the composition of the picture: Borris is the leader, Potty and Pinky are usually side by side, Mary-Lou is right behind Borris, and Dorothy wisely remains in the background, observing.',
  },
  swan_and_dipper_ii: {
    title: 'Swan and Dipper II - Dipper',
    imgs: {
      full: {
        src: require('./swan_and_dipper_ii_2021_web.jpg'),
        width: 938,
        height: 1500,
      },
      thumb: {
        src: require('./swan_and_dipper_ii_2021_thumb.jpg'),
        width: 468,
        height: 748,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on cold-pressed watercolour paper. 150 x 240 mm, 2021.',
    price: 80,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "A commissioned painting that was part of a set: two paintings of the same size, one portraying a dipper, the other one a mute swan.<br /><br />For this piece the commissioner described deep greens along with the depth of river waters, as the dipper lives by this rushing streams. The vegetation is different, it's denser and instead of reeds you see more trees, bushes and ivy.",
  },
  swan_and_dipper_i: {
    title: 'Swan and Dipper I - Mute Swan',
    imgs: {
      full: {
        src: require('./swan_and_dipper_i_2021_web.jpg'),
        width: 937,
        height: 1500,
      },
      thumb: {
        src: require('./swan_and_dipper_i_2021_thumb.jpg'),
        width: 468,
        height: 749,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on cold-pressed watercolour paper. 150 x 240 mm, 2021.',
    price: 80,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'A commissioned painting that was part of a set: two paintings of the same size, one portraying a dipper, the other a mute swan.<br /><br />The commissioner wanted bright colours for this one, inspired by the lake and local swans at Cork city. Lots of blue and the sparkle of sunshine makes this painting a fresh, sunny view of this majestic bird!',
  },
  wicklows_eldest: {
    title: "Wicklow's Eldest",
    imgs: {
      full: {
        src: require('./wicklows_eldest_2021_web.jpg'),
        width: 800,
        height: 1000,
      },
      thumb: {
        src: require('./wicklows_eldest_2021_thumb.jpg'),
        width: 400,
        height: 500,
      },
    },
    year: '2021',
    description:
      'Ink, watercolour, gauche and acrylics on acrylic paper. 405 x 508 mm, 2021.',
    price: 206,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1097952734/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "There are some creatures in this world who don't have to do a thing to give the impression of a deity. Or some kind of godfather of the mountains. Here's this old goat surrounded by the younger generation. He's half blind, and limping. If I step closer he tries to crawl away, but he would make an easy catch.<br /><br />Yet when he raises that head with his crown, I still see the patriarch, the beast that still demands respect. Time left its mark on him, but he's still there, still the eldest of the mountain!",
  },
  winter_birds_2021: {
    title: 'Winter Birds 2021',
    imgs: {
      full: {
        src: require('./winter_birds_2021_2021_web.jpg'),
        width: 1000,
        height: 1200,
      },
      thumb: {
        src: require('./winter_birds_2021_2021_web.jpg'),
        width: 1000,
        height: 1200,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on watercolour paper. 210 x 210 mm each, 2021.',
    price: 135,
    original: 'NOTYET',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    merch: [
      {
        name: 'Christmas Cards',
        url: 'https://www.etsy.com/ie/listing/1114482253/',
        price: 3.5,
        discount: 0,
      },
    ],
    type: 'animal_watercolour_painting',
    story:
      "Artworks for my 2021 Christmas card collection. The set consists of 4 bird paintings: the bullfinch, the robin, the chaffinch and the wren.<br /><br />I wanted to pick garden birds that I haven't painted before, that's why my choice fell upon these less commonly known garden visitors. But of course you can't have a Christmas collection without the Robin Redbreast!",
  },
  black_swan: {
    title: 'Black Swan',
    imgs: {
      full: {
        src: require('./black_swan_2021_web.jpg'),
        width: 707,
        height: 1000,
      },
      thumb: {
        src: require('./black_swan_2021_thumb.jpg'),
        width: 353,
        height: 499,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on cold-pressed watercolour paper. 297 x 420 mm, 2021.',
    price: 120,
    original: 'SOLD',
    prints: 'AVAILABLE',
    printPrice: 21.95,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: 'https://www.etsy.com/ie/listing/1098620588/',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'Another example of my mantra, "black is never black, white is never white". There are so many shades and colours in here as the light is reflected on the feathers.<br /><br />For this painting I used my oldest reference to date, a photo I took in London with a super old phone, at least 5-6 years ago. The image was quite blurry, so I had to come up with most of the details by myself. So this painting is half imagination!<br /><br />I think this is my most elegant painting so far. Quite minimalist, yet full of details. The simple beauty of a bird.',
  },
  flamingo: {
    title: 'Flamingo',
    imgs: {
      full: {
        src: require('./flamingo_2021_web.jpg'),
        width: 1000,
        height: 707,
      },
      thumb: {
        src: require('./flamingo_2021_thumb.jpg'),
        width: 600,
        height: 424,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on Arches HP 300gsm paper. 420 x 297 mm, 2021.',
    price: 130,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1069020336/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "The idea of the flamingo came from an Instagram pollI ran back in the spring of 2021 to ask what people's favourite birds were. And the flamingo was among the answers. I saw that, and thought yay, that would be fun! But I had to wait until I could get a really good reference (due to Covid I couldn't get into the flamingo enclosure in Dublin Zoo) but I finally managed to get some from FOTA in Cork. Awesome, I thought, it was time to use pinks!<br /><br />And emerald green, rose, crimson red and scarlet... Very unusual colour choices for me. But it was such a great change to paint a not very Irish, but exciting bird. Working on this piece inspired me to plan some macaws or other colourful bird paintings. And because it was so different in the colours, I really feel like I was on an exotic holiday as far as you can get when you're submerged in art.",
  },
  queen_of_hearts: {
    title: 'Queen of Hearts',
    imgs: {
      full: {
        src: require('./queen_of_hearts_2021_web.jpg'),
        width: 707,
        height: 1000,
      },
      thumb: {
        src: require('./queen_of_hearts_2021_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on Arches HP 300gsm paper. 380 x 580 mm, 2021.',
    price: 350,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1076496289/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "This painting is a record breaker. To date, it spent far the most time stretched on my drawing board as it's one of the most complex paintings I've done.<br /><br />The concept is based on a dream I had about swans under cherry blossoms. I don't remember too much of the whole thing, but I liked the feeling of the scene. I started sketching it, and since all the details were so blurry I thought maybe something abstract would work here. Dreams are pretty abstract anyway, most of the time they don't really make any sense! So I said all right, let's paint the feeling.<br /><br />And what was that feeling? The feeling is mostly the only thing that remains after dreams, after all the details are lost. And it was just simply pleasant. Something beautiful, something that doesn't want to be more than that. I had the idea of the two swans circling each other first, and started adding the blossoms. But the picture was still so empty (it's a big sheet of paper!) and at that point I decided to go crazy with details. I wanted something that was difficult, challenging to paint. So I added the roses and swallows, because why not? Anything goes that's pretty!",
  },
  wood_screecher: {
    title: 'Wood Screecher',
    imgs: {
      full: {
        src: require('./wood_screecher_2021_web.jpg'),
        width: 707,
        height: 1000,
      },
      thumb: {
        src: require('./wood_screecher_2021_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on hot-pressed watercolour paper. 297 x 420 mm, 2021.',
    price: 130,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1068745539/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'Although jays are secretive birds, they visit the feeders in many garden. As for being considered a passerine, well... The latin name "Garrulus Glandarius" roughly means "noisy acorn bird". Jays love stashing acorns, and their singing capabilities you can guess from the latin name, and also from the title of my painting, which is based on their Irish name, Scréachóg choille.<br /><br />This painting is part of my Corvid series along with the Rook, Jackdaw and the Hooded Crow!',
  },
  domesticated_nomad: {
    title: 'Domesticated Nomad',
    imgs: {
      full: {
        src: require('./domesticated_nomad_2021_web.jpg'),
        width: 725,
        height: 1000,
      },
      thumb: {
        src: require('./domesticated_nomad_2021_thumb.jpg'),
        width: 435,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on Fabriano HP 640g. 280 x 380 mm, 2021.',
    price: 130,
    original: 'SOLD',
    prints: 'AVAILABLE',
    printPrice: 21.95,
    buyUrl: 'https://www.etsy.com/ie/listing/1050988933/',
    ship: 'FREE_IRL',
    printUrl: 'https://www.etsy.com/ie/listing/1098629694/',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "Sometimes I venture away from painting birds because I see something extraordinary in other animals in nature. Such a topic that can make me do that is the search for the wild in all creatures. I believe even the cutest little puppy, humble livestock, and our beloved songbirds have that inner, untamed core deep down that connects them to the wilderness.<br /><br />I see sheep wandering around on any mountain in Ireland. Just when I finish climbing up a steep slope in some drizzle while the wind is constantly making me feel like I'm freezing, I face these creatures. They don't seem to mind the conditions that make me feel so uncomfortable, just stare at me like they've never seen a human being. But certainly they have, I can see the tags in the ears, the marking on the coat... Yet at that moment I cannot name a living thing that is more adapted to that wild environment. The domesticated nomads of Ireland.",
  },
  kingfisher: {
    title: 'Kingfisher',
    imgs: {
      full: {
        src: require('./kingfisher_2021_web.jpg'),
        width: 707,
        height: 1000,
      },
      thumb: {
        src: require('./kingfisher_2021_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 297 x 420 mm, 2021.',
    price: 145,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "This piece was a commissioned birthday present. In the background you can see the hints of the ruins by the River Dalyan in Turkey, and the reeds were inspired by that beautiful environment. By painting this I had to realise again what I felt while painting a blackbird and listening to their song was the same connection that this painting means to the receiver: wherever we live, the birds of our childhood, our place of origin connects us to our roots.<br /><br />That won't show on these photos, but I used a tiny bit of metallic watercolours on the bird, just to add to its shine, since this bird looks like a jewel. This remains a feature reserved for the original.",
  },
  hoodie: {
    title: 'Hoodie',
    imgs: {
      full: {
        src: require('./hoodie_2021_web.jpg'),
        width: 661,
        height: 1000,
      },
      thumb: {
        src: require('./hoodie_2021_thumb.jpg'),
        width: 397,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 380 x 580 mm, 2021.',
    price: 265,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1041416667/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "The hooded crow, unlike the rook, is associated with misfortune and death. Morrigan or Badhbh the Celtic war goddess takes the form of this bird, and that deity is no joke. To live up to that heritage hooded crows are not like the kind rooks foraging on the ground, meaning harm to no one. They are cunning and dangerous. They hunt (apparently their method includes BEHEADING their prey). And they are large. You'd better not mess with hoodies.",
  },
  before_the_explosion: {
    title: 'Before the Explosion',
    imgs: {
      full: {
        src: require('./before_the_explosion_2021_web.jpg'),
        width: 1500,
        height: 763,
      },
      thumb: {
        src: require('./before_the_explosion_2021_thumb.jpg'),
        width: 900,
        height: 458,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 568 x 290 mm, 2021.',
    price: 220,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'I wanted to represent power and speed here. That energy! Look at this creature, it\'s exploding. At this time the target is a ball, but rewind this a few thousand years and think about how that prey felt... When you have less than a second to spare before that explosion, before this manifested energy burst is at you.<br /><br />Thanks <a href="https://www.instagram.com/max_border_collie27" target="_blank" rel="noopener noreferrer">Max</a> and <a href="https://www.instagram.com/akutyafotos.hu" target="_blank" rel="noopener noreferrer">akutyafotos.hu</a> for the resources!',
  },
  thirsty_chough: {
    title: 'Thirsty Chough',
    imgs: {
      full: {
        src: require('./thirsty_chough_2021_web.jpg'),
        width: 1000,
        height: 707,
      },
      thumb: {
        src: require('./thirsty_chough_2021_thumb.jpg'),
        width: 600,
        height: 424,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 380 x 280 mm, 2021.',
    price: 100,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "This painting came to be because a fellow bird lover offered me to use her lovely photos of a chough drinking from a stream, on the Isle of Man. I loved the vivid colours on the photos, especially the many little pebbles and stones!<br /><br />I love painting corvids and this was the first time I could paint a chough. It's not a common bird, here in Dublin I've never seen one. Maybe because of that not too much lore is built around them. But for us birdwatchers it's definitely a sight to cheer us up with that bright red beak and legs! This is a wonderful bird.",
  },
  ivy_gentleman: {
    title: 'Ivy Gentleman',
    imgs: {
      full: {
        src: require('./ivy_gentleman_2021_web.jpg'),
        width: 1000,
        height: 1000,
      },
      thumb: {
        src: require('./ivy_gentleman_2021_thumb.jpg'),
        width: 600,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 285 x 285 mm, 2021.',
    price: 125,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1013343201/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    merch: [
      {
        name: "Women's short sleeved tee",
        url: 'https://www.etsy.com/ie/listing/1007951583/',
        price: 30.5,
        discount: 0,
        shop: 'clothingShop',
      },
    ],
    type: 'animal_watercolour_painting',
    story:
      "I promised I would paint more blackbirds, and I was constantly reminded of that promise thanks to the beautiful song they perform every day in the garden. This painting was made as a t-shirt print, and once I made the t-shirt available I asked people if they would have liked to see more background. Well, they wouldn't have!<br /><br />I picked the ivy as the floral part of the image. It's a common plant, yet it has a real character: I immediately associate it with woodland ground. And to add a little spice to it, why not include some fairy mushrooms?",
  },
  red_kite: {
    title: 'Red Kite',
    imgs: {
      full: {
        src: require('./red_kite_2021_web.jpg'),
        width: 674,
        height: 1000,
      },
      thumb: {
        src: require('./red_kite_2021_thumb.jpg'),
        width: 404,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 190 x 280 mm, 2021. Framed.',
    price: 183,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1003130725/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "Part of my Irish birds of prey portrait study series.<br /><br />As one of the success stories of reintroducing a species to Ireland, I had to include the Red Kite in my raptor series. This beautiful red coated bird is now quite common in the Eastern part of the isle. An eagle-like bird, but its tail is very distinctive with its V-shape. Most raptors are disliked by some because they take garden birds or pigeons, but the Red Kite prefers cleaning up after road kills, even though they hunt if needed.<br /><br />A fun fact: they like to steal human clothes, especially underwear, to decorate their nests! Who wouldn't adore such a bird? :D",
  },
  greyhead: {
    title: 'Greyhead',
    imgs: {
      full: {
        src: require('./greyhead_2021_web.jpg'),
        width: 707,
        height: 1000,
      },
      thumb: {
        src: require('./greyhead_2021_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 420 x 594 mm, 2021.',
    price: 250,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1003113817/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'Greyhead is an old name for jackdaws. In old times they were referred to as "jacks" or just "daws", but I really liked the name greyhead. It has this reference to an old person who\'s hair had gone grey, like some old wise man. And jackdaws are smart birds indeed.<br /><br />Still what I like the most about them is their funny sound! I can\'t include that in this painting, but whenever I look at it in my head I can hear that high-pitched "caw", like a horn on a kid\'s tricycle!<br /><br />This painting is a follow-up to my rook portrait, "Bone Raven".',
  },
  kestrel: {
    title: 'Kestrel',
    imgs: {
      full: {
        src: require('./kestrel_2021_web.jpg'),
        width: 674,
        height: 1000,
      },
      thumb: {
        src: require('./kestrel_2021_thumb.jpg'),
        width: 404,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 190 x 280 mm, 2021. Framed.',
    price: 183,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/994928233/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'Part of my Irish birds of prey portrait study series.<br /><br />He\'s is such a small bird of prey! Like all of their kind, they are masters of their crafts: they can hover to target small rodents from the air. In Hungarian the kestrel has "red" in its name (vörös vércse) referring to that bright coat they have. In my portrait I pictured the bird from the front, but part of that beautiful coat is still visible. This is a pretty bird!',
  },
  caladrius: {
    title: 'Caladrius',
    imgs: {
      full: {
        src: require('./caladrius_2021_web.jpg'),
        width: 1000,
        height: 704,
      },
      thumb: {
        src: require('./caladrius_2021_thumb.jpg'),
        width: 600,
        height: 422,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 594 x 420 mm, 2021.',
    price: 220,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "This piece was a donation to the fundraiser art auction of ARC Cancer Support, an organisation that helps people with cancer diagnosis and their families to handle the new situation.<br /><br />I chose the subject of the painting specifically for the occasion, so this epic white bird is Caladrius, the bird of healing. If he sits on the chest of the sick person and turns his head right, the person lives. I took this positive scenario, showing the mythical bird pecking at a dark serpent, the Sickness.<br /><br />I'm hoping everyone who needs him can reach out for Caladrius!",
  },
  sunny_december_day: {
    title: 'Sunny December Day',
    imgs: {
      full: {
        src: require('./sunny_december_day_2021_web.jpg'),
        width: 1000,
        height: 736,
      },
      thumb: {
        src: require('./sunny_december_day_2021_thumb.jpg'),
        width: 600,
        height: 442,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 380 x 280 mm, 2021.',
    price: 120,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "This is a painting I made for Curlew Action, an organisation dedicated to the conservation of Curlews in the UK and Ireland.<br /><br />Curlews are not so rare in my area, but I only see them solitary, and I've spotted a pair only once. The problem, as I've learnt, is the decline of breeding pairs as they can't successfully breed here. This artwork was created for the org's art and poetry competition. The theme: What does the Curlew mean to you?<br /><br />My answer in short is that I cannot imagine a seashore without them, they are part of the picture I have in mind when I think of these shores. The image I painted here is an illustration of this picture, a lonely curlew foraging in a winter day.",
  },
  musket: {
    title: 'Musket',
    imgs: {
      full: {
        src: require('./musket_2021_web.jpg'),
        width: 673,
        height: 1000,
      },
      thumb: {
        src: require('./musket_2021_thumb.jpg'),
        width: 404,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 190 x 280 mm, 2021. Framed.',
    price: 183,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/964272120/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "Being one of the most seen raptor for me in lockdown, the sparrowhawk had to be included in my small birds of prey portrait series (along with the Peregrine and Red Kite). It's amazing how different the face of each bird is. While the Peregrine has almost completely black eyes that gives him a beast-like look, the hawk has bright yellow-red eye with a definite pupil - that kind of hawk eye that gives you the chill with its fierce look.",
  },
  hawk_of_st_annes: {
    title: "Hawk of St Anne's",
    imgs: {
      full: {
        src: require('./hawk_of_st_annes_2021_web.jpg'),
        width: 697,
        height: 1000,
      },
      thumb: {
        src: require('./hawk_of_st_annes_2021_thumb.jpg'),
        width: 418,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 210 x 297 mm, 2021.',
    price: 120,
    original: 'SOLD',
    prints: 'AVAILABLE',
    printPrice: 22.95,
    buyUrl: '',
    printUrl: 'https://www.etsy.com/ie/listing/957620520/',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'It\'s amazing how certain birds can adapt to urban life, and while most of us think of pigeons and sparrows, where there is prey, the predators appear. Sparrowhawks are common in city parks, such as St Anne\'s Park in Dublin, and these neighbours inspired this painting.<br /><br />The original painting was a commissioned one. Special thanks to <a href="https://www.futterer-wildlife.com/" target="_blank" rel="noopener noreferrer">Matthias Futterer</a> for letting me use his wonderful photograph of a sparrowhawk as reference.',
  },
  the_birdfeeder: {
    title: 'The Birdfeeder',
    imgs: {
      full: {
        src: require('./the_birdfeeder_2021_web.jpg'),
        width: 705,
        height: 1000,
      },
      thumb: {
        src: require('./the_birdfeeder_2021_thumb.jpg'),
        width: 423,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 210 x 297 mm, 2021.',
    price: 90,
    original: 'SOLD',
    prints: 'AVAILABLE',
    printPrice: 21.95,
    buyUrl: '',
    printUrl: 'https://www.etsy.com/ie/listing/971588331/',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "For many of us who have a birdfeeder in the garden the little visitors are like family: we look at them as one of our own, our friends, who are there every day to cheer up up with their acrobatics around the seeds. Robins and blue tits are favourites everywhere. This painting captures that view that every bird lover wishes to see, a busy feeder with three of the most popular songbirds.<br /><br />The original painting was a commissioned one, a gift for Mother's day.",
  },
  bone_raven: {
    title: 'Bone Raven',
    imgs: {
      full: {
        src: require('./bone_raven_2021_web.jpg'),
        width: 707,
        height: 1000,
      },
      thumb: {
        src: require('./bone_raven_2021_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 420 x 594 mm, 2021.',
    price: 250,
    original: 'SOLD',
    prints: 'AVAILABLE',
    printPrice: 23.95,
    buyUrl: '',
    printUrl: 'https://www.etsy.com/ie/listing/992037141/',
    discount: 0,
    discountPrint: 0,
    merch: [
      {
        name: "Women's short sleeved tee",
        url: 'https://www.etsy.com/ie/listing/995282674/',
        price: 32.5,
        discount: 0,
        shop: 'clothingShop',
      },
    ],
    type: 'animal_watercolour_painting',
    story:
      "I had some uneasiness with rooks. Like most corvids in their groups they give a quite haunted feeling to any place, and the rook's face resembles the plague masks used in middle ages, so it's quite a scary bird. Or rather it was a scary one, as I painted this fluffy one (reference photo taken on a windy day) I saw the intelligence in his eyes and I can't really see them as frightening any more.<br /><br />Yes the rook's face is not a cuddly one, but the beautiful irridescence in the black feathers and the elegance as they glide and land makes them outstanding. Once a rook landed just beside me on a fence, and didn't get scared when I looked him straight in the eyes. What I saw was an intimidating cunning, maybe with some curiosity. It's said corvids can recognise human faces! This is a remarkable bird who deserves the credit on a large, A2 sized painting.",
  },
  peregrine: {
    title: 'Peregrine',
    imgs: {
      full: {
        src: require('./peregrine_2021_web.jpg'),
        width: 772,
        height: 1000,
      },
      thumb: {
        src: require('./peregrine_2021_thumb.jpg'),
        width: 463,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 216 x 278 mm, 2021. Framed.',
    price: 183,
    original: 'MSG',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "The Peregrine, the fastest bird in the world (and apparently the fastest creature as well).<br /><br />I knew sooner or later I needed to honour this bird, but to capture the personality of a raptor is not easy. You can't see them from your window, and observe them while you daydream. You need to go after them, look for them, and hunting a hunter is anything but easy.",
  },
  bistre_doe: {
    title: 'Bistre Doe',
    imgs: {
      full: {
        src: require('./bistre_doe_2021_web.jpg'),
        width: 800,
        height: 1000,
      },
      thumb: {
        src: require('./bistre_doe_2021_thumb.jpg'),
        width: 480,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 405 x 508 mm, 2021.',
    price: 206,
    original: 'MSG',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'This is a doe I saw at Phoenix Park, Dublin. I was there early in the morning when the park was still empty, in early autumn, and the sun was still warm and bright. This dark coloured one caught my eye, as her coat was almost glowing in that morning sunlight.<br /><br />How captivating is that eye? Such a gentle expression, but the light glows in there just the same. The star of the show. How can you stand out from the crowd when you are just a doe, and everyone is looking for those antlers? Just stand up and shine!',
  },
  the_hug: {
    title: 'The Hug',
    imgs: {
      full: {
        src: require('./the_hug_2021_web.jpg'),
        width: 1500,
        height: 1500,
      },
      thumb: {
        src: require('./the_hug_2021_thumb.jpg'),
        width: 600,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 112 x 278 mm, 2021.',
    price: 55,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    printUrl: '',
    merch: [
      {
        name: 'Bookmark Set',
        url: 'https://www.etsy.com/ie/listing/964814293/',
        price: 4.5,
        discount: 0,
        shop: 'cardsAndBookmarksShop',
      },
    ],
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "Mute swans became symbols of love because they mate for years. It might last for life, but the truth is the pair can separate. Doesn't that bring them even closer to human relationships?<br /><br />I'm so amazed by the sight of affection being displayed among animals, especially birds. They are fundamentally so different than us, yet these gestures are so similar. You can immediately tell if those birds form a pair.",
  },
  low_tide_tale: {
    title: 'Low Tide Tale',
    imgs: {
      full: {
        src: require('./low_tide_tale_2021_web.jpg'),
        width: 792,
        height: 1000,
      },
      thumb: {
        src: require('./low_tide_tale_2021_thumb.jpg'),
        width: 475,
        height: 600,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on paper. 195 x 255 mm, 2021.',
    price: 90,
    original: 'MSG',
    prints: 'NOTYET',
    merch: [
      {
        name: 'Greeting Card',
        url: 'https://www.etsy.com/ie/listing/953617477/',
        price: 3.5,
        discount: 0,
        shop: 'cardsAndBookmarksShop',
      },
    ],
    printPrice: 0,
    buyUrl: '',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'When I was working on the drawing, it just started to look more and more like an illustration that reminded me of my childhood adventures in the wilderness of lakes, sneaking in the swampy parts without a care, discovering nests and catching lizards and frogs...<br /><br />I could tell a hundred tales from those times, and maybe subconsciously I illustrated that experience in this painting. When I encounter such a beautiful bird standing like a statue, I dare not move, I just watch, as this white angel is looking for prey.',
  },
  spear: {
    title: 'Spear',
    imgs: {
      full: {
        src: require('./spear_2021_web.jpg'),
        width: 1000,
        height: 800,
      },
      thumb: {
        src: require('./spear_2021_thumb.jpg'),
        width: 600,
        height: 480,
      },
    },
    year: '2021',
    description: 'Ink and watercolour on acrylic paper. 508 x 405 mm, 2021.',
    price: 200,
    original: 'SOLD',
    prints: 'AVAILABLE',
    printPrice: 24.95,
    buyUrl: '',
    printUrl: 'https://www.etsy.com/ie/listing/957623104/',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "I never really thought any bird would be scary, at least I've never been scared of any. But look at that eye. I see the beast from horror movies, that frame that gets frozen just before it strikes and you know how it will end. Herons are no cuddly little chicks, and while I was painting this portrait I thought for the first time this bird is scary.<br /><br />I got the chance to have a close look at the beak, too. It's full of scratches from all the strikes that went down in water, and probably came out with a catch. This bird is like the old hunter who still has some tricks up in his sleeves!",
  },
  buttercups: {
    title: 'Buttercups',
    imgs: {
      full: {
        src: require('./buttercups_2021_web.jpg'),
        width: 714,
        height: 1000,
      },
      thumb: {
        src: require('./buttercups_2021_thumb.jpg'),
        width: 428,
        height: 600,
      },
    },
    year: '2021',
    description:
      'Ink and watercolour on watercolour paper. 210 x 297 mm, 2021.',
    price: 94,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    ship: 'FREE_IRL',
    buyUrl: 'https://www.etsy.com/ie/listing/947459543/',
    printUrl: '',
    merch: [
      {
        name: 'Unisex t-shirt',
        url: 'https://www.etsy.com/ie/listing/941546699/',
        price: 32,
        discount: 0,
        shop: 'clothingShop',
      },
      {
        name: 'Greeting cards',
        url: 'https://www.etsy.com/ie/listing/943508801/',
        price: 3.5,
        discount: 0,
        shop: 'cardsAndBookmarksShop',
      },
      {
        name: 'Postcard set',
        url: 'https://www.etsy.com/ie/listing/928314268/',
        price: 5,
        discount: 0,
        shop: 'cardsAndBookmarksShop',
      },
    ],
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'This is the third of my spring collection prints: a pair of robins and an Irish wildflower, Buttercups. Also my first artwork in 2021! Inspired by the courting habits of the European Robin: the male offers treats to the female, and feeds her.<br /><br />This painting was created as a t-shirt print, but postcards and greeting cards are also made with it!',
  },
};

export default imgData_2021;
