import React from 'react';

export const logo = () => (
  <svg
    version="1.1"
    id="logo_1_"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="110px"
    height="100px"
    viewBox="0 0 110 100"
  >
    <g id="Shape_1">
      <g>
        <path
          fill="#211E1C"
          d="M108.499,46.944c-3.914-9.26-21.998-14.494-29.421-15.702
          c-7.423-1.208-26.722-1.879-36.034,8.186C33.731,49.494,21.99,68.685,37.24,86.131c11.731,15.167,25.642,15.03,33.335,12.749
          c9.166-2.77,16.6-18.654,11.067-26.572c-5.533-7.918-21.729-8.857-21.729-7.918c0,0.94,12.011,4.831,13.361,11.407
          c1.82,8.866-16.563,19.299-29.556,0.134c-7.693-16.641,8.503-26.035,13.361-28.45c4.859-2.415,36.139-5.811,43.052,3.489
          c6.883,9.26-3.509,31.672-1.754,32.074C100.131,83.447,112.412,56.204,108.499,46.944z"
        />
      </g>
    </g>
    <g id="Shape_2">
      <g>
        <path
          fill="#D40707"
          d="M26.974,55.797c0.006,0.02,0.008,0.039,0.013,0.059
          C27.086,55.703,27.085,55.668,26.974,55.797z M13.581,68.964c3.285-1.978,15.371-5.989,13.407-13.108
          C26.16,57.142,17.822,67.31,0.001,68.079c5.745,16.131,20.257,16.974,30.237,16.289C15.357,77.613,5.622,73.209,13.581,68.964z"
        />
      </g>
    </g>
    <g id="Shape_2_copy">
      <g>
        <path
          fill="#211E1C"
          d="M18.182,3.842C5.27,25.216,17.77,42.22,27.795,52.741
          c-6.158-22.897-10.202-37.86,1.974-32.899c5.281,1.838,21.018,11.63,27.246,2.836C54.793,22.945,35.57,23.051,18.182,3.842z
           M57.069,22.609c-0.017,0.025-0.037,0.045-0.054,0.07C57.279,22.647,57.317,22.613,57.069,22.609z"
        />
      </g>
    </g>
    <g id="Shape_2_copy_2">
      <g>
        <path
          fill="#211E1C"
          d="M73.562-0.002C60.935,3.938,59.88,15.005,60.131,22.651
          c5.635-11.184,9.31-18.5,12.369-12.3c1.437,2.565,4.2,11.913,9.757,10.61C81.286,20.292,73.66,13.638,73.562-0.002z
           M82.303,20.952c-0.016,0.004-0.03,0.005-0.046,0.009C82.372,21.04,82.399,21.04,82.303,20.952z"
        />
      </g>
    </g>
  </svg>
);

export const logoText = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 452.94 247.47"
  >
    <g>
      <path
        d="M355.21,74.46c0-3.19,0.5-5.07-0.08-6.53c-4.57-11.59-2.05-23.28-0.68-34.94c0.41-3.46,1.21-6.88,1.72-10.33
		c1.4-9.41,13.46-14.63,21.53-6.97c4.61,4.38,10.56,7.32,15.31,11.58c5.67,5.07,10.45,11.14,16.1,16.24
		c5.67,5.11,10.73,10.82,17.35,15.08c3.18,2.05,4.83,7.75,5.51,12.06c0.66,4.15,1.38,7.52,4.68,10.44
		c4.53,4.01,6.2,14.06,3.47,17.96c-3.89,5.59-14.31,8.76-20.19,6.15c-3.35-1.49-3.72-8.35-0.66-12.3c3.1-4,2.57-7.53-0.75-11.16
		c-3.55-3.88-6.38-8.42-9.88-12.36c-2.09-2.34-4.6-5.16-7.39-5.86c-6.32-1.57-12.7-4.2-19.53-2.06c-1.52,0.48-3.32,0.25-4.96,0.09
		c-6.43-0.62-9.22,2.81-7.57,8.94c2.71,10.07,5.14,20.22,7.75,30.32c0.41,1.59,0.96,3.19,1.71,4.64c3.41,6.58,2.36,12.02-2.83,14.1
		c-4.6,1.84-13.89-2.39-14.68-7.52c-1.9-12.28-10.89-20.77-15.88-31.36c-0.37-0.77-1.81-1.04-2.76-1.54
		c-0.41,0.82-1.18,1.65-1.17,2.47c0.11,8-4.79,13.62-9.44,19.14c-3.41,4.05-11.08,4.35-15.7,1.12c-6.05-4.24-11.86-8.82-17.92-13.05
		c-0.96-0.67-2.65-0.31-4.01-0.42c0.04,1.68-0.37,3.55,0.23,5c1.16,2.79,2.35,5.72-0.51,7.68c-2.54,1.74-5.89,3.56-8.68,3.28
		c-2.72-0.27-5.96-2.62-7.63-4.99c-6.88-9.78-10.78-20.7-11.92-32.74c-0.27-2.86-2.77-6.07-5.15-8c-2.62-2.12-3.13,1-3.48,2.87
		c-1.58,8.49-5.75,15.62-11.76,21.59c-2.66,2.64-5.94,4.75-9.19,6.69c-7.59,4.54-13.16-0.89-19.15-4.19
		c-5.62-3.1-11.26-6.17-16.99-9.07c-1-0.51-2.89-0.56-3.62,0.06c-0.72,0.61-0.81,2.4-0.6,3.57c0.96,5.46,2.19,10.88,3.19,16.33
		c1.15,6.27-2.39,11.91-8,12.99c-5.72,1.1-11.32-4.05-12.77-11.39c-0.57-2.85-1.86-5.56-2.73-8.36c-2.94-9.44-10.88-8.64-18.03-8.28
		c-9.11,0.46-9.85,2.05-5.83,10.39c2.13,4.43,4.67,8.69,7.34,12.82c3.09,4.78,3.37,13.59,0.16,18.57c-0.34,0.53-0.89,1.02-1.45,1.28
		c-4.2,1.96-14.55-3.84-16-8.26c-3.21-9.76-6.85-19.39-10.44-29.02c-1.39-3.71-3.97-4.35-8-3.69c-6.8,1.11-13.76,1.25-20.67,1.65
		c-6.57,0.39-7.37,0.57-6.53,6.76c1.25,9.31,3.16,18.57,5.41,27.7c1.33,5.39,1.65,10.17-2.39,14.4c-2.31,2.42-1.69,4.16,1.11,5.31
		c13.16,5.39,20.78,17.01,29.43,27.11c10.08,11.76,11.19,34.58,1.96,47.05c-6.98,9.41-14.67,18.8-25.37,24.77
		c-2.14,1.19-3.93,2.98-5.95,4.4c-1.05,0.74-2.18,1.7-3.37,1.85c-8.23,1.09-16.47,2.1-24.74,2.88c-1.88,0.18-4.37-0.08-5.65-1.2
		c-1.69-1.48-3.37-4.02-3.33-6.07c0.03-1.59,2.45-4,4.25-4.52c4.27-1.23,5.18-3.48,4.05-7.42c-2.56-8.92-5.01-17.87-7.45-26.82
		c-0.48-1.76-0.72-3.58-1.01-5.39c-2.06-12.98-5.07-25.63-11.7-37.2c-3.69-6.46,1.6-18.92,7.84-22.54
		c8.22-4.76,16.85-3.85,26.73-3.41c-3.03-6.87-1.44-14.4-8.78-18.62c-4.23-2.43-7.98-5.76-11.65-9.03
		c-1.07-0.95-1.12-3.06-1.64-4.64c1.65-0.35,3.73-1.5,4.87-0.9c3.01,1.6,5.56,4.03,8.39,6c1.51,1.05,3.22,1.81,4.83,2.7
		c0.65-1.58,1.83-3.15,1.85-4.73c0.09-7.41,0.99-15.04-5.62-20.82c-2.03-1.78-3.14-4.61-4.67-6.96c-2.23,1.66-4.35,3.47-6.7,4.93
		c-4.23,2.62-8.44,5.38-12.96,7.41c-4.03,1.82-4.76,4.76-3.38,8.09c1.62,3.92,3.68,7.84,6.36,11.09c2.58,3.12,4.17,6.35,2.93,9.98
		c-0.78,2.27-3,4.31-5.04,5.81c-1.33,0.98-3.51,0.83-5.31,1.13c-5.41,0.93-7.12-0.18-8.46-5.52c-1.25-5-2.42-10.02-3.73-15
		c-1.29-4.9-3.19-9.33-7.62-12.49c-4.09-2.91-4.26-7.88-1.78-12.47c1.1-2.04,0.93-4.78,1.22-7.22c0.07-0.61-0.39-1.27-0.47-1.93
		c-1.39-11.79-2.96-23.56-4-35.38c-0.25-2.8,0.86-5.93,2.07-8.58c1.49-3.26,0.63-4.63-2.42-6.1c-4.46-2.15-8.38-2.08-11.83,1.55
		c-2.48,2.61-4.81,2.1-6.82-0.18c-2.87-3.26-6.75-6-5.07-11.58C2.18,3.51,10.95-1.54,17.67,0.43c4.9,1.44,9.6,3.55,14.42,5.27
		c1.21,0.43,2.62,0.86,3.83,0.66c9.99-1.66,16.72,4.85,24.21,9.48c0.83,0.51,1.31,1.8,2.15,2.03c7.09,1.96,9.98,7.9,14.1,13.21
		c7.41,9.55,8.9,19.63,6.57,30.89c-0.26,1.28-0.31,2.6-0.46,3.9c0.41,0.1,0.82,0.2,1.23,0.3c1.96-4.72,4.14-9.37,5.84-14.18
		c3.49-9.88,10.81-15.26,21.11-14.33c2.76,0.25,5.69,2.1,7.94,3.96c4.55,3.77,8.78,7.96,12.95,12.16c1.49,1.5,2.8,3.41,3.52,5.37
		c2.48,6.72,8.02,7.96,14.08,8.08c4.15,0.08,8.3-0.53,12.46-0.56c3.91-0.03,5.41-1.92,5.17-5.62c-0.21-3.32-0.34-6.65-0.57-9.97
		c-0.59-8.4,4.25-12.97,12.46-12.46c5.91,0.37,10.1,2.76,14.15,7.1c5.76,6.16,12.11,11.95,18.93,16.89
		c4.73,3.43,10.61,5.34,16.12,7.58c3.41,1.38,6.36,0.31,8.8-2.73c8.51-10.61,10.55-22.19,6.4-35.16c-1.18-3.69-1.68-7.87-1.36-11.72
		c0.39-4.54,3.31-5.52,7.03-2.93c1.49,1.03,2.59,2.65,4.12,3.59c4.46,2.71,8.85,5.65,13.63,7.66c4.1,1.72,7.66,2.91,10.72,7.03
		c9.85,13.27,20.58,25.89,31.23,38.54c2.3,2.73,5.54,4.76,8.59,6.73c4.05,2.61,6.34,1.81,8.09-2.74
		c4.34-11.26,2.39-21.52-4.21-31.59c-4.14-6.32-7.06-13.49-10.12-20.45c-1.77-4.03,0.51-10.03,3.65-11.36
		c3.39-1.44,8.17,1.2,10.29,5.72c5.08,10.8,9.97,21.7,15.28,32.39c2.77,5.58,6.13,10.89,9.49,16.15
		C350.59,70.97,352.53,72.08,355.21,74.46z M90.78,231.67c2.12-1.03,5.03-1.7,6.89-3.48c5.6-5.37,10.76-11.2,16.14-16.8
		c8.87-9.24,7.23-20.64,3.63-30.5c-6.51-17.87-22.28-25.73-39.65-30.12c-7.26-1.84-9.17,0.88-7.24,8.12
		c2.06,7.69,3.97,15.42,5.98,23.12c1.09,4.18,2.22,8.35,3.42,12.5C83.41,206.46,86.91,218.41,90.78,231.67z M37,22.87
		c1.12,3.22,2.35,5.62,2.77,8.15c0.91,5.56,1.36,11.18,2.07,16.77c0.83,6.59,1.48,13.23,2.73,19.75c0.41,2.14,2.04,5.21,3.75,5.72
		c7.63,2.27,23.08-14.56,20.72-22.17c-0.15-0.47-0.42-0.91-0.62-1.37C62.51,35.86,52.05,27.29,37,22.87z M384.42,43.04
		c-2.87-6.37-7.42-9.12-12.69-10.74c-0.97-0.3-3.32,1.07-3.66,2.11c-1.07,3.29-1.5,6.78-2.04,10.22c-0.02,0.11,1.45,0.73,2.17,0.64
		C373.24,44.66,378.26,43.91,384.42,43.04z M118.45,67.26c-1.66-6.01-6.34-10.71-10.76-9.94c-2.67,0.46-4.96,3.6-7.09,5.83
		c-0.18,0.19,1.93,3.78,3.17,3.91C108.44,67.55,113.19,67.26,118.45,67.26z"
      />
      <path
        d="M381.65,189.25c-0.73,4.76-1.01,9.09-2.15,13.19c-0.91,3.26-2.36,6.64-4.48,9.23c-4.55,5.59-10.72,5.39-15.3-0.23
		c-3.88-4.76-7.41-9.79-11.17-14.65c-0.76-0.98-2.27-2.57-2.71-2.35c-1.18,0.56-2.18,1.83-2.89,3.03
		c-3.19,5.37-5.79,11.14-9.47,16.13c-4.24,5.75-10.21,6.86-17.02,3.21c1.62,5.7,1.41,10.64-2.32,15.2
		c-4.26,5.2-9.93,5.66-15.46,4.13c-5.67-1.57-12.24-2.47-14.81-9.4c-1.22-3.29-0.4-5.22,2.73-6.85c8.03-4.17,8.41-5.3,3.68-12.76
		c-4.98-7.84-10.33-15.43-15.53-23.13c-2.03-3-4.8-3.9-8.27-3c-4.51,1.16-9.17,1.87-13.49,3.51c-2.34,0.89-4.17,3.25-6.1,5.09
		c-0.94,0.89-1.43,2.25-2.37,3.13c-5.93,5.53-7.38,10.37-4.21,17.75c0.89,2.06,3.5,3.95,5.74,4.73c3.07,1.07,6.53,1.14,9.84,1.4
		c5.73,0.46,7.46,2.14,7.95,8.11c0.56,6.78-1.2,10.56-6.49,10.96c-5.69,0.43-11.49-0.53-17.24-0.97c-4.4-0.34-8.77-0.97-13.17-1.2
		c-3.44-0.18-6.9,0.21-10.34,0.08c-5.62-0.21-11.24-0.91-16.85-0.86c-2.5,0.02-4.95,1.66-7.51,2.02c-3.09,0.43-6.88,1.43-9.28,0.16
		c-6.63-3.53-13.43-3.61-20.62-4.11c-6.11-0.42-12.72-2.98-17.79-6.49c-5.57-3.86-3.7-15.86,2.02-19.85
		c0.27-0.19,0.59-0.32,0.86-0.52c10.55-7.65,13.05-7.93,7.51-22.13c-4.57-11.72-3.82-24.56,2.5-35.76
		c3.02-5.37,8.21-10.04,13.43-13.52c6.45-4.3,22.72-2.5,27.14,6.63c2.64,5.47,7.07,10.07,7.17,16.61c0.04,3.1,0.93,6.74-0.34,9.22
		c-4.36,8.52-8.47,17.56-14.68,24.63c-5.46,6.23-3.94,11.92-2.62,18.03c1.26,5.83,6.3,8.15,11.62,8.72
		c6.6,0.71,13.3,0.73,19.95,0.73c3.66,0,5.1-1.62,5.27-5.96c0.3-7.31,2.23-14.54,3.35-21.82c0.39-2.54,0.32-5.15,0.55-7.72
		c0.2-2.24-0.33-5.42,0.93-6.55c7.35-6.62,8-16.25,11.83-24.43c1.93-4.13,4.29-8.18,7.11-11.74c3.36-4.23,10.46-4.07,14.08,0.04
		c4.81,5.46,9.63,10.94,13.93,16.8c3.23,4.41,6.88,6.16,11.99,4.19c4.76-1.84,9.55-3.64,14.12-5.89c4.38-2.16,10.77-0.63,13.09,4.55
		c2.12,4.73,2.84,10.07,4.42,15.07c1.36,4.33,2.61,8.8,4.74,12.76c1.86,3.45,6,2.98,7.18-0.77c1.38-4.38,1.68-9.09,2.77-13.57
		c0.88-3.63,2.12-7.2,3.51-10.68c0.28-0.7,1.95-1.24,2.95-1.21c8.84,0.25,18.72,6.88,20.22,16.7c0.34,2.24,1.99,4.28,3.14,6.36
		c0.06,0.11,1.66-0.38,1.65-0.54c-0.11-2.35-0.2-4.72-0.62-7.03c-1.62-8.98-3.21-17.99-1-27.04c0.42-1.73,2.92-4.46,3.99-4.26
		c3.2,0.61,7.27,1.64,8.97,3.99c2.52,3.48,4.4,8.17,4.58,12.43c0.34,8.19,3.79,14.71,8.67,20.65c4.49,5.47,8.28,11.02,10.06,18.1
		c1.85,7.35,11.06,12.98,18.84,12.55c2.83-0.15,5.69,0.05,8.48-0.36c4.83-0.71,7.29-4.44,6.83-9.29
		c-0.96-10.15-5.8-18.61-12.87-25.26c-9.23-8.68-16.52-18.2-19.37-30.68c-1.51-6.62-3.34-13.18,2.35-19.19
		c7.88-8.32,27.53-7.74,34.13,1.6c2.14,3.03,3.88,6.63,4.64,10.22c0.4,1.88-1.19,5.06-2.87,6.23c-1.31,0.91-4.52,0.23-6.22-0.82
		c-2.93-1.81-5.22-4.64-8.03-6.68c-1.49-1.08-3.64-2.08-5.28-1.82c-1.06,0.17-2.07,2.6-2.54,4.18c-1.82,6.11-0.05,11.15,4.09,16.07
		c7.57,8.98,15.4,17.92,21.52,27.87c4.32,7.03,6.6,15.54,8.52,23.69c0.73,3.09-1.45,7.49-3.51,10.45
		c-2.89,4.15-6.49,8.09-10.54,11.11c-8.42,6.28-26.64,4.13-33.79-3.55c-3.83-4.12-7.44-8.21-8.43-14.32
		C395.56,205.61,385.55,191.52,381.65,189.25z M177.67,176.78c6.1-3.2,11.48-13.14,10.06-18.38c-0.8-2.94-3.36-5.91-5.93-7.71
		c-2.86-2-7.85,0.55-7.53,3.58C175.02,161.55,176.41,168.77,177.67,176.78z M307.13,201.2c0.45-0.22,0.9-0.43,1.35-0.65
		c-1.39-4.61-2.61-9.28-4.23-13.82c-1.1-3.1-2.16-6.6-4.35-8.81c-1.5-1.51-4.92-1.13-7.48-1.59c0.36,1.86,0.41,3.86,1.16,5.54
		c0.8,1.78,2.28,3.24,3.43,4.87C300.39,191.56,303.76,196.38,307.13,201.2z M261.3,163.17c-2.93-1.82-4.53-3.16-6.38-3.73
		c-0.45-0.14-2.6,2.54-2.33,3.43c0.37,1.21,2.22,2.48,3.6,2.7C257.34,165.76,258.72,164.46,261.3,163.17z"
      />
    </g>
  </svg>
);

export const instagram = () => (
  <svg
    version="1.1"
    id="insta_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="612px"
    height="612px"
    viewBox="0 0 612 612"
  >
    <g>
      <g id="insta">
        <g>
          <path
            d="M513.381,84.865h-56.158c-18.346,0-33.281,14.936-33.281,33.281v56.144c0,18.345,14.936,33.281,33.281,33.281h56.158
				c18.345,0,33.281-14.936,33.281-33.281v-56.144C546.662,99.8,531.726,84.865,513.381,84.865z M517.519,174.29
				c0,2.288-1.851,4.138-4.138,4.138h-56.158c-2.288,0-4.139-1.851-4.139-4.138v-56.144c0-2.288,1.865-4.138,4.139-4.138h56.158
				c2.272,0,4.138,1.851,4.138,4.138V174.29z M480.857,7.286H131.143C58.839,7.286,0,66.125,0,138.429v335.142
				c0,72.305,58.839,131.143,131.143,131.143h349.714C553.16,604.714,612,545.876,612,473.571V138.429
				C612,66.125,553.16,7.286,480.857,7.286z M582.857,473.571c0,56.246-45.755,102-102,102H131.143c-56.246,0-102-45.754-102-102
				V255h146.895c-27.802,30.979-44.895,71.764-44.895,116.571c0,96.42,78.438,174.857,174.857,174.857
				s174.857-78.438,174.857-174.857c0-44.807-17.093-85.592-44.895-116.571h146.895V473.571z M306,225.857
				c80.347,0,145.714,65.368,145.714,145.714c0,80.348-65.367,145.715-145.714,145.715s-145.714-65.367-145.714-145.715
				C160.286,291.225,225.653,225.857,306,225.857z M582.857,225.857H402.448c-27.671-18.375-60.807-29.143-96.448-29.143
				c-35.627,0-68.777,10.768-96.448,29.143H29.143v-87.428c0-56.246,45.754-102,102-102h349.714c56.245,0,102,45.754,102,102
				V225.857z M306,473.571c56.246,0,102-45.754,102-102c0-56.244-45.754-102-102-102c-56.246,0-102,45.755-102,102
				C204,427.817,249.754,473.571,306,473.571z M306,298.715c40.174,0,72.857,32.685,72.857,72.856
				c0,40.174-32.684,72.857-72.857,72.857c-40.173,0-72.857-32.684-72.857-72.857C233.143,331.399,265.827,298.715,306,298.715z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const twitter = () => (
  <svg
    version="1.1"
    id="twitter_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="612px"
    height="612px"
    viewBox="0 0 612 612"
  >
    <g>
      <g>
        <path
          d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
          c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
          c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
          c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
          c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
          c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
          C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
          C480.224,136.96,497.728,118.496,512,97.248z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const deviantart = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 455.73 455.73"
  >
    <path
      d="M0,0v455.73h455.73V0H0z M335.09,114.76l-66.02,130.73h66.02v91.07H223.08l-34.87,69.03h-67.57v-59.6l67.82-134.29h-67.82
	v-91.07h113.81l35.6-70.5h65.04V114.76z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const close = () => (
  <svg id="Capa_1" viewBox="0 0 386.667 386.667">
    <path d="m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" />
  </svg>
);

export const check = () => (
  <svg id="Capa_1" viewBox="0 0 515.556 515.556">
    <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
  </svg>
);

export const facebook = () => (
  <svg
    className="icon-facebook"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="792px"
    height="792px"
    viewBox="0 0 792 792"
  >
    <g>
      <g id="facebook">
        <g>
          <path
            d="M614.582,0.128L494.197,0.184c0,0-2.439-0.184-6.826-0.184C462.67,0,337.754,6.863,303.768,140.809
				c-0.532,1.486-11.965,35.014-12.148,112.988h-114.2c-10.129,0-18.351,8.221-18.351,18.351v105.226
				c0,10.13,8.222,18.351,18.351,18.351h124.898v377.924c0,10.13,8.221,18.352,18.351,18.352H463.68
				c10.129,0,18.352-8.222,18.352-18.352V396.109h125.301c10.131,0,18.352-8.221,18.352-18.351V272.148
				c0-10.13-8.221-18.351-18.352-18.351H482.287v-45.163c0-30.078,19.086-62.21,72.672-62.21h59.623
				c10.129,0,18.35-8.221,18.35-18.351V18.479C632.932,8.35,624.729,0.128,614.582,0.128z M596.23,109.722h-41.271
				c-75.553,0-109.373,49.677-109.373,98.913v63.514c0,10.13,8.221,18.351,18.35,18.351H588.98v68.909H463.68
				c-10.131,0-18.352,8.221-18.352,18.351v377.539H339.02V377.374c0-10.13-8.221-18.351-18.351-18.351H195.771v-68.523h114.346
				c4.936,0,9.652-1.982,13.103-5.505s5.34-8.276,5.249-13.213c-1.633-83.461,9.836-118.604,10.387-120.384
				C365.923,44.979,459.385,36.702,487.371,36.702c2.551,0,4.074,0.074,5.561,0.129H596.23V109.722L596.23,109.722z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const arrow = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 492.004 492.004"
  >
    <g>
      <g>
        <path
          d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
			c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
			v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
			c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
			l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const menu = () => (
  <div
    className="menuIcon"
    style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <div style={{ height: '6px', width: '100%' }} />
    <div style={{ height: '6px', width: '100%' }} />
    <div style={{ height: '6px', width: '100%' }} />
  </div>
);
